import React, {useState, useRef, useEffect} from "react";
import {format, addMonths, subMonths, isBefore, addDays} from "date-fns";
import CalendarIcon from "assets/images/icons/CalendarIcon";
import ChevronLeftIcon from "assets/images/icons/ChevronLeftIcon";
import ChevronRightIcon from "assets/images/icons/ChevronRightIcon";

interface DateRangeInputProps {
    label?: string;
    id: string;
    error?: string;
    type: string;
    value: string;
    onChange: (startDate: string, endDate: string) => void;
    placeholder: string;
    calendarAlignment?: "left" | "right" | "center";
    className?: string;
    optional?: string;
    allowPastDates?: boolean;
    required?: boolean;
    isClickDisabled?: boolean;
}

const DateRangeInput: React.FC<DateRangeInputProps> = ({
                                                           label,
                                                           id,
                                                           error,
                                                           value,
                                                           onChange,
                                                           placeholder,
                                                           calendarAlignment = "right",
                                                           className = "w-full",
                                                           optional,
                                                           type,
                                                           required,
                                                           allowPastDates = false,
                                                           isClickDisabled,
                                                       }) => {
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [nextMonth, setNextMonth] = useState(addMonths(new Date(), 1));
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const calendarRef = useRef<HTMLDivElement | null>(null);
    const today = new Date();
    const [maxRangeDays, setMaxRangeDays] = useState<number | null>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
                setIsCalendarOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const handlePreviousMonth = () => {
        setCurrentMonth(subMonths(currentMonth, 1));
        setNextMonth(subMonths(nextMonth, 1));
    };

    const handleNextMonth = () => {
        setCurrentMonth(addMonths(currentMonth, 1));
        setNextMonth(addMonths(nextMonth, 1));
    };

    const handleSelectRange = (days: number | null) => {
        setMaxRangeDays(days);
        setStartDate(new Date());
        setEndDate(days ? addDays(new Date(), days) : null);
    };

    const handleDateClick = (selectedDate: Date) => {
        if (!startDate || (startDate && endDate)) {
            setStartDate(selectedDate);
            setEndDate(null);
        } else if (selectedDate < startDate) {
            setEndDate(startDate);
            setStartDate(selectedDate);
        } else {
            setEndDate(selectedDate);
        }
    };

    const handleApply = () => {
        if (startDate && endDate) {
            onChange(format(startDate, "MM/dd/yyyy"), format(endDate, "MM/dd/yyyy"))
            setIsCalendarOpen(false);
        }
    };

    const handleInputClick = () => {
        setIsCalendarOpen(!isCalendarOpen);
    };

    const handleClear = () => {
        setStartDate(null);
        setEndDate(null);
        setIsCalendarOpen(false);
        onChange("", "");
    }


    const renderCalendar = (month: Date) => {
        const daysInMonth = new Date(month.getFullYear(), month.getMonth() + 1, 0).getDate();
        const firstDayOfMonth = new Date(month.getFullYear(), month.getMonth(), 1).getDay();
        const days = [...Array(firstDayOfMonth).fill(null), ...Array.from({length: daysInMonth}, (_, i) => i + 1)];

        return (
            <div className="">
                <div className="grid grid-cols-5 items-center gap-x-3 mx-1.5 pb-3">
                    <div className="col-span-1">
                        <button
                            type="button"
                            className="size-8 flex justify-center items-center text-gray-600 hover:bg-gray-100 rounded-full focus:outline-none"
                            onClick={handlePreviousMonth}
                            aria-label="Previous"
                        >
                            <ChevronLeftIcon/>
                        </button>
                    </div>
                    <div className="col-span-3 text-center text-sm font-medium text-gray-600">
                        {format(month, "MMMM yyyy")}
                    </div>
                    <div className="col-span-1 flex justify-end">
                        <button
                            type="button"
                            className="size-8 flex justify-center items-center text-gray-600 hover:bg-gray-100 rounded-full focus:outline-none"
                            onClick={handleNextMonth}
                            aria-label="Next"
                        >
                            <ChevronRightIcon/>
                        </button>
                    </div>
                </div>
                <div className="grid grid-cols-7">
                    {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((day) => (
                        <span key={day} className="text-center text-sm text-slate-400 pb-2">
                            {day}
                        </span>
                    ))}
                    {days.map((day, index) => {
                        const date = new Date(month.getFullYear(), month.getMonth(), day || 0);
                        const isSelected =
                            (startDate && date.getTime() === startDate.getTime()) ||
                            (endDate && date.getTime() === endDate.getTime());
                        const inRange = startDate && endDate && date > startDate && date < endDate;
                        const isDisabled = !day || (!allowPastDates && isBefore(date, today));
                        const isStartDate = startDate && date.getTime() === startDate.getTime();

                        return (
                            <div
                                key={index}
                                className={`relative h-10 w-10 my-0.5 flex justify-center items-center
                                                ${isSelected ? (isStartDate ? "bg-gray-100 rounded-l-full" : endDate ? "bg-slate-100 rounded-r-full" : "bg-gray-100") : ""}
                                            `}
                            >
                                <button
                                    key={index}
                                    type="button"
                                    className={`h-10 w-10 flex justify-center items-center text-sm my-0.5
                                                    ${isDisabled ? "text-gray-300" : "text-slate-600"}
                                                    ${isSelected ? "bg-cyan-400 text-white rounded-full" : inRange ? "bg-slate-100" : day ? "hover:bg-cyan-100 rounded-full" : ""}
                                                `}

                                    disabled={isDisabled}
                                    onClick={() => day && handleDateClick(date)}
                                >
                                    {day || ""}
                                </button>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    return (
        <div className="flex flex-col items-start gap-[0.625rem]">
            {label && (
                <label htmlFor={id} className="block text-slate-800 text-sm font-semibold">
                    {label}
                </label>
            )}
            <div className="relative w-56 ">
                <button
                    type="button"
                    className="absolute inset-y-0 left-0 flex py-[9px] px-3 text-gray-400"
                    onClick={handleInputClick}
                >
                    <CalendarIcon/>
                </button>
                <input
                    type="text"
                    id={id}
                    value={
                        startDate && endDate
                            ? `${format(startDate, "MM/dd/yyyy")} - ${format(endDate, "MM/dd/yyyy")}`
                            : value
                    }
                    onChange={() => onChange}
                    required={required}
                    onClick={!isClickDisabled ? handleInputClick : undefined}
                    className={`py-[7px] px-3 pl-10  block border border-slate-200 rounded-lg text-sm text-slate-400 ${
                        error ? "border-red-500" : ""
                    } ${className}`}
                    placeholder={placeholder}
                    readOnly
                />

                {isCalendarOpen && (
                    <div
                        className={`absolute bg-white rounded-lg border border-slate-200   shadow mt-2 mb-5 w-max z-20 flex ${
                            calendarAlignment === "left" ? "-right-60" : "left-10"
                        }`}
                        ref={calendarRef}
                    >
                        <div className="flex flex-rows-2 rounded-t-lg ">
                            <div>
                            <div className="flex flex-col-2 rounded-t-lg ">
                                <div className="bg-gray-50 rounded-t-lg">
                                    <div
                                        className="h-auto w-36 flex-col  rounded-t-lg justify-start items-start inline-flex">
                                        <div className="bg-gray-50 pl-4 pr-4 pt-4 rounded-t-lg">
                                            <button
                                                onClick={() => handleSelectRange(30)}
                                                className="block w-full text-sm font-medium text-slate-600 text-left px-1 py-2  hover:text-cyan-400 rounded-lg "
                                            >
                                                Next 30 days
                                            </button>
                                            <button
                                                onClick={() => handleSelectRange(60)}
                                                className="block w-full text-sm font-medium text-slate-600 text-left px-1 py-2   hover:text-cyan-400 rounded-lg"
                                            >
                                                Next 60 days
                                            </button>
                                            <button
                                                onClick={() => handleSelectRange(90)}
                                                className="block w-full text-sm font-medium text-slate-600 text-left px-1 py-2   hover:text-cyan-400 rounded-lg"
                                            >
                                                Next 90 days
                                            </button>

                                        </div>
                                        <div className="pl-2 pr-4 pb-4 pt-1">
                                            <button
                                                className="h-10 w-auto px-3 py-2.5 bg-white rounded-lg border border-slate-200 hover:bg-cyan-100  justify-start inline-flex"
                                                onClick={() => handleSelectRange(null)}>
                                                <div
                                                    className="text-sm font-medium text-slate-600 text-left font-['Inter'] leading-tight tracking-tight">
                                                    Custom Range
                                                </div>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                                <div className="flex space-x-4 text-sm font-medium text-slate-600 pt-2">
                                    {renderCalendar(currentMonth)}
                                    {renderCalendar(nextMonth)}
                                </div>
                            </div>

                        <div className="">
                            <div className="w-full rounded-b-lg border-t border-gray-200 bg-white p-4">
                                <div className="flex justify-end items-center space-x-2">
                                    <div className="text-sm text-slate-400 pr-2">
                                        {startDate && endDate && `${format(startDate, "MM/dd/yyyy")} - ${format(endDate, "MM/dd/yyyy")}`}
                                    </div>
                                    <button
                                        onClick={() => setIsCalendarOpen(false)}
                                        className="py-1 px-3 text-sm font-medium text-slate-600 border border-gray-300 rounded-lg hover:bg-gray-50"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleClear}
                                        className="py-1 px-3 text-sm font-medium text-slate-600 border border-gray-300 rounded-lg hover:bg-gray-50"
                                    >
                                        Clear
                                    </button>
                                    <button
                                        onClick={handleApply}
                                        className="py-1 px-3 text-sm font-medium text-white bg-cyan-400 rounded-lg hover:bg-cyan-500"
                                    >
                                        Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                            </div>
                        </div>
                    </div>

                )}
            </div>
        </div>
    );
};

export default DateRangeInput;
