import React, { useEffect, useRef, useState } from 'react';
import { setIsEditing, setUser } from "../features/user/userSlice";
import { logout } from "../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import ChevronDownIcon from "../assets/images/icons/ChevronDownIcon";
import { FaUser } from "react-icons/fa";
import {UserThunks} from "../features/user/userThunks";
import {UserService} from "../services/userService";

interface HeaderProfileProps {
    setIsProfileDrawerOpen: (isOpen: boolean) => void;
    setIsPasswordDrawerOpen: (isOpen: boolean) => void;
    setIsPinDrawerOpen: (isOpen: boolean) => void;
    setIsSignatureDrawerOpen: (isOpen: boolean) => void;
}

const HeaderProfile: React.FC<HeaderProfileProps> = ({ setIsProfileDrawerOpen, setIsPasswordDrawerOpen,setIsSignatureDrawerOpen,setIsPinDrawerOpen }) => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.auth.user);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleMyProfile = () => {
        const refreshUser = async () => {
            await UserService.show(user?.id!).then((response) => {
                dispatch(setUser(response));
                setIsEditing(true);
                toggleDropdown();
                setIsProfileDrawerOpen(true);
            });
        }
        refreshUser();
    };

    const handlePassword = () => {
        toggleDropdown();
        setIsPasswordDrawerOpen(true);

    };
    const handlePin = () => {
        toggleDropdown();
        setIsPinDrawerOpen(true);
    }
    const handleSignature = () => {
        toggleDropdown();
        setIsSignatureDrawerOpen(true);
    }

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node)
        ) {
            setIsDropdownOpen(false);
        }
    };

    const handleLogout = () => {
        dispatch(logout());
        window.location.href = "/login";
    };

    return (
        <div className="flex lg:justify-end items-center">
            <div className="relative inline-block">
                <button
                    id="dropdown-button"
                    type="button"
                    onClick={toggleDropdown}
                    className="py-2 px-2.5 inline-flex items-center gap-x-1.5 text-xs rounded-lg border border-gray-200 bg-white text-gray-800
                        hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700
                        dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 relative"
                >
                    <div
                        className="flex-shrink-0 w-4 h-4 bg-slate-100 rounded-full flex justify-center items-center"
                    >
                        <FaUser className="text-gray-400 size-2" />
                    </div>
                    <div className="flex flex-col">
                        <span>{user?.name}</span>
                    </div>
                    <ChevronDownIcon />
                </button>
                {isDropdownOpen && (
                    <div
                        ref={dropdownRef}
                        className="absolute right-0 mt-2 w-auto bg-white rounded-xl shadow-lg z-10
                            dark:bg-neutral-900"
                    >
                        <div className="bg-slate-100 rounded-t-lg">
                            <div className="w-full flex items-center gap-x-3 pt-1.5 px-4 rounded-lg text-[13px]  font-light text-slate-400">
                                Signed in as
                            </div>
                            <div className="w-full flex items-center gap-x-3 px-4 pb-1.5 rounded-lg text-[13px] text-gray-800">
                                {user?.email}
                            </div>
                        </div>
                        <div className="p-1">
                            <button
                                key="Profile"
                                type="button"
                                onClick={handleMyProfile}
                                className="w-full flex items-center gap-x-3 py-1.5 px-3 rounded-lg text-[13px] text-gray-800 disabled:opacity-50
                                    hover:bg-cyan-100 hover:text-gray-800 disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-cyan-100"
                            >
                                Profile
                            </button>
                            <button
                                key="Password"
                                type="button"
                                onClick={handlePassword}
                                className="w-full flex items-center gap-x-3 py-1.5 px-3 rounded-lg text-[13px] text-gray-800 disabled:opacity-50
                                    hover:bg-cyan-100 hover:text-gray-800 disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-cyan-100"
                            >
                                Password
                            </button>
                            <button
                                key="Pin"
                                type="button"
                                onClick={handlePin}
                                className="w-full flex items-center gap-x-3 py-1.5 px-3 rounded-lg text-[13px] text-gray-800 disabled:opacity-50
                                    hover:bg-cyan-100 hover:text-gray-800 disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-cyan-100"
                            >
                                PIN
                            </button>
                            <button
                                key="Signature"
                                type="button"
                                onClick={handleSignature}
                                className="w-full flex items-center gap-x-3 py-1.5 px-3 rounded-lg text-[13px] text-gray-800 disabled:opacity-50
                                    hover:bg-cyan-100 hover:text-gray-800 disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-cyan-100"
                            >
                                Signature
                            </button>
                            <button
                                key="Logout"
                                type="button"
                                onClick={handleLogout}
                                className="w-full flex items-center gap-x-3 py-1.5 px-3 rounded-lg text-[13px] text-red-500 disabled:opacity-50
                                    hover:bg-red-100 hover:text-red-800 disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-red-100"
                            >
                                Log out
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default HeaderProfile;
