import React from 'react';
import Wysiwyg from 'components/Wysiwyg';
import {ElementProps, TextBlockElement, TextBlockElementClass} from "types/documentTemplateElement";

interface TextBlockElementProps extends ElementProps<TextBlockElementClass> {}

const TextBlockElementComponent: React.FC<TextBlockElementProps> = ({ element, updateElement }) => {
    const handleContentChange = (content: string) => {
        const updatedElement = new TextBlockElementClass({
            ...element,
            properties: { ...element.properties, content },
        });
        updateElement(updatedElement);
    };

    return (
        <div className="w-full m-8">
            <Wysiwyg content={element.properties.content} onContentChange={handleContentChange} />
        </div>
    );
};

export default TextBlockElementComponent;
