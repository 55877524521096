import {Role} from "./role";
import {JobRole} from "./jobRole";

export interface UserInterface {
    id?: string;
    email: string;
    personalEmail: string;
    name: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    role?: Role;
    ssn: string;
    supervisorIds?: string[];
    jobRoleIds?: string[];
    supervisors?: any[];
    status?: string;
    lastLogin?: string;
    roleId?: string;
    locked?: boolean;
    blocked?: boolean;
    signature?: string;
    avatar?: any;
    avatarUrl?: string;
    locations?: any[];
    credentials?: any;
    jobRoles?: JobRole[];
    isSupervisor: boolean;
    userType?: string;
    locationIds?: string[];
}

export class User implements UserInterface {
    id: string;
    email: string;
    name: string;
    personalEmail: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    role: Role;
    ssn: string;
    supervisorIds: string[];
    supervisors?: any[];
    status: any;
    lastLogin: string;
    roleId: string;
    jobRoleIds: string[];
    locked: boolean;
    blocked: boolean;
    signature: string;
    avatar: any;
    avatarUrl?: string;
    locations: any[];
    credentials: any;
    jobRoles: JobRole[];
    isSupervisor: boolean;
    userType: string;
    locationIds?: string[];

    constructor(data: Partial<UserInterface> = {}) {
        this.id = data.id || '';
        this.email = data.email || '';
        this.personalEmail = data.personalEmail || '';
        this.name = data.name || '';
        this.firstName = data.firstName || '';
        this.lastName = data.lastName || '';
        this.phoneNumber = data.phoneNumber || '';
        this.role = new Role(data.role || {});
        this.supervisors = data.supervisors || [];
        this.ssn = data.ssn || '';
        this.supervisorIds = data.supervisorIds || [];
        this.status = data.status || 'active';
        this.lastLogin = data.lastLogin || '';
        this.roleId = data.roleId || '';
        this.locked = data.locked || false;
        this.blocked = data.blocked || false;
        this.signature = data.signature || '';
        this.avatar = data.avatar || '';
        this.avatarUrl = data.avatarUrl || '';
        this.locations = data.locations || [];
        this.jobRoleIds = data.jobRoleIds || [];
        this.credentials = data.credentials || {};
        this.jobRoles = data.jobRoles || [];
        this.isSupervisor = data.isSupervisor || false;
        this.userType = data.userType || '';
        this.locationIds = data.locationIds;
    }

    static fromJson(data: UserInterface): User {
        return new User(data);
    }
    toJson(): UserInterface {
        const rawUser = {
            id: this.id,
            name: this.name,
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            phoneNumber: this.phoneNumber,
            avatar: this.avatar,
            ssn: this.ssn,
            supervisorIds: this.supervisorIds,
            supervisors: this.supervisors,
            status: this.status?.toLowerCase(),
            jobRoleIds: this.jobRoleIds,
            personalEmail: this.personalEmail,
            roleId: this.roleId,
            role: this.role.toJson(),
            isSupervisor: this.isSupervisor,
            locationIds: this.locationIds,
        };

        const filteredUser = Object.fromEntries(
            Object.entries(rawUser).filter(([key, value]) =>
                value !== undefined &&
                value !== null &&
                value !== '' &&
                !(Array.isArray(value) && value.length === 0)
            )
        );

        return filteredUser as UserInterface;
    }
}
