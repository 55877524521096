// Tag.ts

import { Node, mergeAttributes } from '@tiptap/core';

export interface TagOptions {
    HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        tag: {
            /**
             * Inserta un nodo de tag con el label y tagValue proporcionados
             */
            insertTag: (label: string, tagValue: string) => ReturnType;
        };
    }
}

const Tag = Node.create<TagOptions>({
    name: 'tag',

    group: 'inline',

    inline: true,

    selectable: true,

    atom: true,

    content: '', // Indica que el nodo no tiene contenido

    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },

    addAttributes() {
        return {
            label: {
                default: '',
            },
            tagValue: {
                default: '',
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'span[data-tag-value]',
                getAttrs: (element) => {
                    const label = element.getAttribute('data-label');
                    const tagValue = element.getAttribute('data-tag-value');
                    return label && tagValue ? { label, tagValue } : false;
                },
            },
        ];
    },

    renderHTML({ node, HTMLAttributes }) {
        return [
            'span',
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
                'data-label': node.attrs.label,
                'data-tag-value': node.attrs.tagValue,
                class: 'tag-node',
            }),
            node.attrs.label, // Mostramos el label al usuario
        ];
    },

    renderText({ node }) {
        return node.attrs.tagValue; // Usamos tagValue en el contenido interno
    },

    addCommands() {
        return {
            insertTag:
                (label: string, tagValue: string) =>
                    ({ chain }) => {
                        return chain()
                            .insertContent({
                                type: this.name,
                                attrs: { label, tagValue },
                            })
                            .run();
                    },
        };
    },
});

export default Tag;
