import React  from "react";

const DateTimeIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor"
         stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-calendar-clock">
        <path d="M21 7.5V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h3.5"/>
        <path d="M16 2v4"/>
        <path d="M8 2v4"/>
        <path d="M3 10h5"/>
        <path d="M17.5 17.5 16 16.3V14"/>
        <circle cx="16" cy="16" r="6"/>
    </svg>

)
export default DateTimeIcon;
