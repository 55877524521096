import { EditorContent, useEditor, Editor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import classNames from 'classnames';
import Tag from './Tag'
import {useEffect, useRef, useState} from "react";

interface MenuBarProps {
    editor: Editor | null;
}

const tagOptions = [
    { label: 'CURRENT DATE', value: '{CURRENT_DATE}' },
    { label: 'EMPLOYEE FIRST NAME', value: '{EMPLOYEE_FIRST_NAME}' },
    { label: 'COMPANY NAME', value: '{COMPANY_NAME}' },
];

const MenuBar: React.FC<MenuBarProps> = ({ editor }) => {
    const [isTagMenuOpen, setIsTagMenuOpen] = useState(false);
    const [menuPosition, setMenuPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
    const tagButtonRef = useRef<HTMLButtonElement>(null);
    const tagMenuRef = useRef<HTMLDivElement>(null);

    const toggleTagMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setIsTagMenuOpen((prev) => !prev);

        const buttonRect = event.currentTarget.getBoundingClientRect();
        setMenuPosition({
            top: buttonRect.top + buttonRect.height,
            left: buttonRect.left,
        });
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            tagMenuRef.current &&
            !tagMenuRef.current.contains(event.target as Node) &&
            tagButtonRef.current &&
            !tagButtonRef.current.contains(event.target as Node)
        ) {
            setIsTagMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    if (!editor) {
        return null;
    }

    return (
        <div className="flex align-middle gap-x-0.5 border-b border-gray-200 p-2 dark:border-neutral-700">
            {/* Bold */}
            <button
                type="button"
                onClick={() => editor.chain().focus().toggleBold().run()}
                className={`size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent ${
                    editor.isActive('bold')
                        ? 'bg-gray-100 text-gray-800 dark:bg-neutral-700'
                        : 'text-gray-800 hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700'
                }`}
            >
                {/* SVG Icon for Bold */}
                <svg
                    className="shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M14 12a4 4 0 0 0 0-8H6v8"></path>
                    <path d="M15 20a4 4 0 0 0 0-8H6v8Z"></path>
                </svg>
            </button>

            {/* Italic */}
            <button
                type="button"
                onClick={() => editor.chain().focus().toggleItalic().run()}
                className={`size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent ${
                    editor.isActive('italic')
                        ? 'bg-gray-100 text-gray-800 dark:bg-neutral-700'
                        : 'text-gray-800 hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700'
                }`}
            >
                {/* SVG Icon for Italic */}
                <svg
                    className="shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <line x1="19" x2="10" y1="4" y2="4"></line>
                    <line x1="14" x2="5" y1="20" y2="20"></line>
                    <line x1="15" x2="9" y1="4" y2="20"></line>
                </svg>
            </button>

            {/* Underline */}
            <button
                type="button"
                onClick={() => editor.chain().focus().toggleUnderline().run()}
                className={`size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent ${
                    editor.isActive('underline')
                        ? 'bg-gray-100 text-gray-800 dark:bg-neutral-700'
                        : 'text-gray-800 hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700'
                }`}
            >
                {/* SVG Icon for Underline */}
                <svg
                    className="shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M6 4v6a6 6 0 0 0 12 0V4"></path>
                    <line x1="4" x2="20" y1="20" y2="20"></line>
                </svg>
            </button>

            {/* Strike */}
            <button
                type="button"
                onClick={() => editor.chain().focus().toggleStrike().run()}
                className={`size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent ${
                    editor.isActive('strike')
                        ? 'bg-gray-100 text-gray-800 dark:bg-neutral-700'
                        : 'text-gray-800 hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700'
                }`}
            >
                {/* SVG Icon for Strike */}
                <svg
                    className="shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M16 4H9a3 3 0 0 0-2.83 4"></path>
                    <path d="M14 12a4 4 0 0 1 0 8H6"></path>
                    <line x1="4" x2="20" y1="12" y2="12"></line>
                </svg>
            </button>

            {/* Link */}
            <button
                type="button"
                onClick={() => {
                    const url = prompt('Enter the URL');

                    if (url) {
                        editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
                    }
                }}
                className={`size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent ${
                    editor.isActive('link')
                        ? 'bg-gray-100 text-gray-800 dark:bg-neutral-700'
                        : 'text-gray-800 hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700'
                }`}
            >
                {/* SVG Icon for Link */}
                <svg
                    className="shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
                    <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
                </svg>
            </button>

            {/* Ordered List */}
            <button
                type="button"
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                className={`size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent ${
                    editor.isActive('orderedList')
                        ? 'bg-gray-100 text-gray-800 dark:bg-neutral-700'
                        : 'text-gray-800 hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700'
                }`}
            >
                {/* SVG Icon for Ordered List */}
                <svg
                    className="shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <line x1="10" x2="21" y1="6" y2="6"></line>
                    <line x1="10" x2="21" y1="12" y2="12"></line>
                    <line x1="10" x2="21" y1="18" y2="18"></line>
                    <path d="M4 6h1v4"></path>
                    <path d="M4 10h2"></path>
                    <path d="M6 18H4c0-1 2-2 2-3s-1-1.5-2-1"></path>
                </svg>
            </button>

            {/* Bullet List */}
            <button
                type="button"
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={`size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent ${
                    editor.isActive('bulletList')
                        ? 'bg-gray-100 text-gray-800 dark:bg-neutral-700'
                        : 'text-gray-800 hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700'
                }`}
            >
                {/* SVG Icon for Bullet List */}
                <svg
                    className="shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <line x1="8" x2="21" y1="6" y2="6"></line>
                    <line x1="8" x2="21" y1="12" y2="12"></line>
                    <line x1="8" x2="21" y1="18" y2="18"></line>
                    <line x1="3" x2="3.01" y1="6" y2="6"></line>
                    <line x1="3" x2="3.01" y1="12" y2="12"></line>
                    <line x1="3" x2="3.01" y1="18" y2="18"></line>
                </svg>
            </button>

            {/* Blockquote */}
            <button
                type="button"
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                className={`size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent ${
                    editor.isActive('blockquote')
                        ? 'bg-gray-100 text-gray-800 dark:bg-neutral-700'
                        : 'text-gray-800 hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700'
                }`}
            >
                {/* SVG Icon for Blockquote */}
                <svg
                    className="shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M17 6H3"></path>
                    <path d="M21 12H8"></path>
                    <path d="M21 18H8"></path>
                    <path d="M3 12v6"></path>
                </svg>
            </button>

            {/* Code Block */}
            <button
                type="button"
                onClick={() => editor.chain().focus().toggleCodeBlock().run()}
                className={`size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent ${
                    editor.isActive('codeBlock')
                        ? 'bg-gray-100 text-gray-800 dark:bg-neutral-700'
                        : 'text-gray-800 hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700'
                }`}
            >
                {/* SVG Icon for Code Block */}
                <svg
                    className="shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="m18 16 4-4-4-4"></path>
                    <path d="m6 8-4 4 4 4"></path>
                    <path d="m14.5 4-5 16"></path>
                </svg>
            </button>
            <button
                type="button"
                onClick={(event) => toggleTagMenu(event)}
                className="size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                ref={tagButtonRef}
            >
                <svg
                    className="shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path
                        d="M12 2H2V12L11.29 21.29C12.23 22.23 13.77 22.23 14.71 21.29L21.29 14.71C22.23 13.77 22.23 12.23 21.29 11.29L12 2Z"
                        stroke="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M7 7H7.01" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </button>

            {/* Menú Desplegable de Tags */}
            {isTagMenuOpen && (
                <div
                    ref={tagMenuRef}
                    className="fixed z-10 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg dark:bg-neutral-800 dark:border-neutral-700"
                    style={{
                        top: `${menuPosition.top}px`,
                        left: `${menuPosition.left}px`,
                    }}
                >
                    <ul className="py-1">
                        {tagOptions.map((tag) => (
                            <li key={tag.value}>
                                <button
                                    type="button"
                                    onClick={() => {
                                        editor.chain().focus().insertTag(tag.label, tag.value).run();
                                        setIsTagMenuOpen(false);
                                    }}
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left dark:text-white dark:hover:bg-neutral-700"
                                >
                                    {tag.label}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

const extensions = [
    StarterKit,
    BulletList,
    OrderedList,
    Underline,
    Link,
    Tag
];

interface WysiwygProps {
    content?: string;
    onContentChange?: (content: string) => void;
}

const Wysiwyg: React.FC<WysiwygProps> = ({ content, onContentChange }) => {
    const editor = useEditor({
        extensions: extensions,
        content: content,
        editorProps: {
            attributes: {
                class: classNames(
                    'prose max-w-none focus:outline-none',
                    'h-[10rem] overflow-auto p-2',
                    '[&_ol]:list-decimal [&_ul]:list-disc'
                ),
            },
        },
        onUpdate: ({ editor }) => {
            const updatedContent = editor.getHTML();
            console.log(updatedContent);
            if (onContentChange) {
                onContentChange(updatedContent);
            }
        },
    });

    return (
        <div className="bg-white border border-gray-200 rounded-xl overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">
            <div id="hs-editor-tiptap">
                {/* Menu Bar */}
                <MenuBar editor={editor} />

                {/* Editor Content */}
                <EditorContent editor={editor} />
            </div>
        </div>
    );
};

export default Wysiwyg;
