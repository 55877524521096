import React from 'react';
import { UrlInputElementClass } from 'types/documentTemplateElement';
import { ElementProps } from 'types/documentTemplateElement';
import TextInput from "../inputs/TextInput";

const UrlInputElementComponent: React.FC<ElementProps<UrlInputElementClass>> = ({ element, updateElement }) => {

  return (
      <div className="w-full m-8">
        <TextInput
            id={element.id}
            type="text"
            label={element.properties.showLabel ? element.properties.label : ""}
            value=""
            placeholder={"URL"}
            required={element.properties.required}
            onChange={() => {
            }}
        />
      </div>
  );
};

export default UrlInputElementComponent;
