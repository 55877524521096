import React from 'react';

interface CircleAlertIconProps {
    className?: string
}

const CircleAlertIconIcon = ({className}: CircleAlertIconProps) => (
    <svg  width="14" height="14" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="alert-circle" clip-path="url(#clip0_1098_13919)">
            <path id="Vector"
                  d="M5.00001 9.16671C7.3012 9.16671 9.16668 7.30123 9.16668 5.00004C9.16668 2.69885 7.3012 0.833374 5.00001 0.833374C2.69882 0.833374 0.833344 2.69885 0.833344 5.00004C0.833344 7.30123 2.69882 9.16671 5.00001 9.16671Z"
                  stroke="currentColor" strokeWidth="0.7" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_2" d="M5 3.33337V5.00004" stroke="#854D0E" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_3" d="M5 6.66663H5.00417" stroke="#854D0E" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_1098_13919">
                <rect width="10" height="10" fill="white"/>
            </clipPath>
        </defs>
    </svg>


);
export default CircleAlertIconIcon;
