import React from 'react';
import { RatingInputElement } from 'types/documentTemplateElement';
import RadioInputHorizontal from "../inputs/RadioInputHorizontal";
import TextInput from "../inputs/TextInput";
import CustomSelect from "../inputs/CustomSelect";

interface RatingInputPropertiesProps {
  element: RatingInputElement;
  updateElement: (updatedElement: RatingInputElement) => void;
}

const RatingInputProperties: React.FC<RatingInputPropertiesProps> = ({ element, updateElement }) => {
  const handleChange = (
          e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
      ) => {
          const {id, value, type} = e.target;
          updateElement({
              ...element,
              properties: {
                  ...element.properties,
                  [id]: value
              },
          });
      };

    const handleMaxRatingChange = (value: number) => {
        console.log(value);
        updateElement({...element, properties: {...element.properties, maxRating: value}});
    }

  return (
      <div className="space-y-5 w-full">
          <RadioInputHorizontal
              label="Label"
              options={[
                  {id: 'show-label-yes', label: 'Yes', value: true},
                  {id: 'show-label-no', label: 'No', value: false},
              ]}
              selectedValue={element.properties.showLabel}
              onChange={(value) => updateElement({...element, properties: {...element.properties, showLabel: value}})}
          />
          {element.properties.showLabel && (
              <TextInput
                  id="label"
                  type="input"
                  label="Label Text"
                  value={element.properties.label}
                  onChange={handleChange}
              />
          )}
          <RadioInputHorizontal
              label="Required"
              options={[
                  {id: 'required-yes', label: 'Yes', value: true},
                  {id: 'required-no', label: 'No', value: false},
              ]}
              selectedValue={element.properties.required}
              onChange={(value) => updateElement({...element, properties: {...element.properties, required: value}})}
          />
          <div>
              <label
                  htmlFor="maxRating"
                  className="block text-sm font-semibold text-slate-800 dark:text-white mb-2"
              >
                  Rating Range
              </label>
              <CustomSelect
                  options={[
                      {label: '1-5', value: 5},
                      {label: '1-10', value: 10},
                  ]}
                  value={element.properties.maxRating}
                  onChange={(value) => handleMaxRatingChange(value.value)}
              />
          </div>
      </div>
  );
};

export default RatingInputProperties;
