import React from 'react';
import { CurrencyInputElement } from 'types/documentTemplateElement';
import RadioInputHorizontal from "../inputs/RadioInputHorizontal";
import TextInput from "../inputs/TextInput";

interface CurrencyInputPropertiesProps {
  element: CurrencyInputElement;
  updateElement: (updatedElement: CurrencyInputElement) => void;
}

const CurrencyInputProperties: React.FC<CurrencyInputPropertiesProps> = ({ element, updateElement }) => {
  const handleChange = (
          e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
      ) => {
          const {id, value, type} = e.target;
          updateElement({
              ...element,
              properties: {
                  ...element.properties,
                  [id]: value
              },
          });
      };

    return (
        <div className="space-y-5 w-full">
            <RadioInputHorizontal
                label="Label"
                options={[
                    {id: 'show-label-yes', label: 'Yes', value: true},
                    {id: 'show-label-no', label: 'No', value: false},
                ]}
                selectedValue={element.properties.showLabel}
                onChange={(value) => updateElement({...element, properties: {...element.properties, showLabel: value}})}
            />
            {element.properties.showLabel && (
                <TextInput
                    id="label"
                    type="input"
                    label="Label Text"
                    value={element.properties.label}
                    onChange={handleChange}
                />
            )}
            <RadioInputHorizontal
                label="Required"
                options={[
                    {id: 'required-yes', label: 'Yes', value: true},
                    {id: 'required-no', label: 'No', value: false},
                ]}
                selectedValue={element.properties.required}
                onChange={(value) => updateElement({...element, properties: {...element.properties, required: value}})}
            />
        </div>
  );
};

export default CurrencyInputProperties;
