import {Role} from "./role";

export interface UserInvitationInterface {
    id?: string;
    email: string;
    personalEmail: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    invite: boolean | undefined;
    role?: Role;
    ssn: string;
    supervisorUserIds?: string[];
    supervisors?: any[];
    jobRoleIds?: string[];
    supervisorIds?: string[];
    locationIds?: string[];
    status?: string;
    lastLogin?: string;
    credentialIds?: string[];
    userType: string;
    isSupervisor?: boolean;
    staffMember: boolean | undefined;
    userId?: string;


}

export class UserInvitation implements UserInvitationInterface {
    id: string;
    email: string;
    personalEmail: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    role: Role;
    ssn: string;
    supervisorIds: string[];
    locationIds: string[];
    jobRoleIds: string[];
    supervisors?: any[];
    status: string;
    lastLogin: string;
    credentialIds: string[];
    userType: string;
    invite: boolean | undefined;
    isSupervisor?: boolean;
    staffMember: boolean | undefined;
    userId?: string;


    constructor(data: Partial<UserInvitationInterface> = {}) {
        this.id = data.id || '';
        this.email = data.email || '';
        this.personalEmail = data.personalEmail || '';
        this.firstName = data.firstName || '';
        this.lastName = data.lastName || '';
        this.phoneNumber = data.phoneNumber || '';
        this.role = new Role(data.role || {});
        this.supervisors = data.supervisors || [];
        this.ssn = data.ssn || '';
        this.supervisorIds = data.supervisorIds || [];
        this.locationIds = data.locationIds || [];
        this.jobRoleIds = data.jobRoleIds || [];
        this.status = data.status || 'Pending';
        this.lastLogin = data.lastLogin || '';
        this.credentialIds = data.credentialIds || [];
        this.userType = data.userType || 'user';
        this.invite = true;
        this.staffMember = true;
        this.isSupervisor = data.isSupervisor || false;
        this.userId = data.userId || '';
    }

    toJson() {
        return {
            id: this.id,
            email: this.email,
            personalEmail: this.personalEmail,
            firstName: this.firstName,
            lastName: this.lastName,
            phoneNumber: this.phoneNumber,
            role: this.role.toJson(),
            ssn: this.ssn,
            supervisorIds: this.supervisorIds,
            jobRoleIds: this.jobRoleIds,
            credentialIds: this.credentialIds,
            locationIds: this.locationIds,
            isSupervisor: this.isSupervisor,
        };
    }

    static fromJson(data: UserInvitationInterface): UserInvitation {
        return new UserInvitation(data);
    }
}
