
import {createEntitySlice} from "utils/createEntitySlice";
import {UserTraining} from "types/userTraining";
import {UserTrainingThunks} from "features/userTraining/userTrainingThunks";

export interface UserTrainingState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    userTrainings?: any;
    refresh: number;
    pagy?: any;
    userTraining?: any;
    isEditing?: boolean;
    activeTab?: string;
}

const initialState: UserTrainingState = {
    status: 'idle',
    error: null,
    userTrainings: [],
    refresh: 0,
    userTraining: null,
    pagy: {},
    isEditing: false,
    activeTab: 'All',
};
const userTrainingSlice = createEntitySlice<UserTraining>({
    name: 'training',
    initialState,
    thunks: UserTrainingThunks,
    entityKey: 'userTraining',
    entitiesKey: 'userTrainings',
    reducers: {
    },
});


export const { setActiveTab, setEntity: setUserTraining, setIsEditing} = userTrainingSlice.actions;
export {initialState as userTrainingInitialState};
export default userTrainingSlice.reducer;
